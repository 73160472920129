import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { breakpoints, Container } from "../utils/styles"
import SEO from "../components/seo"
import ReactPlayer from "react-player"

const VideosGrid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 0 1rem;
  width: 90%;
  max-width: 1280px;
  margin: auto;
`

const VideoItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000000;
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
  position: relative;
  padding-top: 56.25%;
`

const Placeholder = styled.div`
  & p {
    font-family: "HelveticaNeue-Regular";
    color: #000000;
    font-size: 1.5rem;
    letter-spacing: -0.6px;
    text-transform: uppercase;
    text-align: center;
    padding-top: 2.5rem;
  }
`

const VideosPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allPrismicVideosList {
        edges {
          node {
            data {
              playlist {
                video_title
                video_url {
                  url
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO title="Home" />
      <Container>
        <VideosGrid>
          {data.allPrismicVideosList.edges[0].node.data.playlist.length > 1 ? (
            data.allPrismicVideosList.edges[0].node.data.playlist.map((video, index) => {
              return index > 1 ? (
                <VideoItem key={video.video_url.url} style={{ marginTop: index > 0 ? '2rem' : 0 }}>
                  <ReactPlayer light controls width={'100%'} height={'100%'} style={{ position: 'absolute', top: 0, left: 0 }} url={video.video_url.url} />
                </VideoItem>
              ) : null
            })
          ) : (
            <Placeholder>
              <p>No videos uploaded. More to come soon.</p>
            </Placeholder>
          )}
        </VideosGrid>
      </Container>
    </>
  )
}

export default VideosPage
